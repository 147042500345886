import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store/store";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";
import { BrowserRouter as Router } from "react-router-dom";
import Context1 from "./components/Context1/Context1";
import { GoogleOAuthProvider } from "@react-oauth/google";

const { language } = store.getState();
const root = ReactDOM.createRoot(document.getElementById("root"));

i18next.init({
  interpolation: { escapeValue: false }, // React already does escaping
  lng: language.language,
  resources: {
    en: {
      common: common_en, // 'common' is our custom namespace
    },
    ar: {
      common: common_ar,
    },
  },
});

root.render(
  <GoogleOAuthProvider clientId="969711276871-ioq89c8bfkmlb3klavr5sdj2db3dt1ip.apps.googleusercontent.com">
  <I18nextProvider i18n={i18next}>
    <Provider store={store}>
      <Context1>
        <App />
      </Context1>
    </Provider>
  </I18nextProvider>
  </GoogleOAuthProvider>,
  // document.getElementById("root")
);
