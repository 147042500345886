import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Avatar, Badge, IconButton, Menu, MenuItem } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import AppsIcon from "@mui/icons-material/Apps";
import MenuIcon from "@mui/icons-material/Menu";
import { ContextData } from "../Context1/Context1";
import FolderOpenOutlinedIcon from "@mui/icons-material/FolderOpenOutlined";
import { set_handleLogout } from "../../functions/common";
import { useDispatch, useSelector } from "react-redux";
import { getCookie } from "../../functions/utils";
import { logoutSuccess } from "../../slices/user/userSlice";
import { domain, TOKEN_FRONT_URL, VIKNBOOKS_FRONT_URL } from "../../settings";
import { useTranslation } from "react-i18next";
import {
  getUserDatas,
} from "../../api/AuthAPI";
import { dataURLtoFile } from "../../functions/common";

function Header({ hidemenu }) {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const [fileList, setFileList] = useState([]);
  const [menu1, setMenu1] = useState(false);
  const [state1, setState1] = useState({ profilePic: "" });
  const { SideMenu } = useContext(ContextData);
  const [showMenu, setShowMenu] = SideMenu;
  let navigate = useNavigate();
  ///Notification bell icon -- MUI
  function notificationsLabel(count) {
    if (count === 0) {
      return "no notifications";
    }
    if (count > 99) {
      return "more than 99 notifications";
    }
    return `${count} notifications`;
  }

  //   Three dot menu icon --MUI
  const ITEM_HEIGHT = 48;
  const options = [
    {
      title: t("Logout"),
      urlLink: "/activitylog",
    },
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (link) => {
    console.log("============================");
    console.log(link);
    // navigate(link);
    setAnchorEl("");
  };

  const { username, email, user_id } = useSelector((state) => state.user);
  const [access, setAccess] = useState(getCookie("VBID"));
  const [callRefresh, setcallRefresh] = useState(false);

  // const FetchData = async () => {
  //   const userInformations = await fetch(getUserDatas, {
  //     method: "POST",
  //     headers: {
  //       "content-type": "application/json",
  //       Authorization: `Bearer ${access}`,
  //     },
  //     body: JSON.stringify({
  //       user_id: user_id,
  //     }),
  //   }).then((response) => response.json());

  //   // const countryResponse = await fetch(countryUrl, {
  //   //   method: "GET",
  //   //   headers: {
  //   //     "content-type": "application/json",
  //   //   },
  //   // }).then((response) => response.json());

  //   if (userInformations.StatusCode == 6000) {
  //     let file = [];
  //     if (userInformations.data.profile_pic) {
  //       file = await dataURLtoFile(userInformations.data.profile_pic);
  //       file = file[0].originFileObj;
  //     }
  //     setFileList(file);
  //     setState1({
  //       ...state1,
  //       // firstname: userInformations.data.first_name,
  //       // middlename: userInformations.data.middle_name,
  //       // lastname: userInformations.data.last_name,
  //       // email: userInformations.data.email,
  //       // country: userInformations.data.countryid,
  //       // countryName: userInformations.data.countryName,
  //       // phone: userInformations.data.phone,
  //       // address1: userInformations.data.address,
  //       // address2: userInformations.data.address1,
  //       profilePic: userInformations.data.profile_pic,
  //       // countries: countryResponse.data,
  //     });
  //   }
  // };
  const FetchData = async () => {
    try {
      console.log("Fetching data...");
      
      if (!access) {
        throw new Error("Access token is missing!");
      }
  
      const response = await fetch(getUserDatas, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access}`,
        },
        body: JSON.stringify({ user_id: user_id }),
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
  
      const userInformations = await response.json();
      console.log("User Information:", userInformations);
  
      if (userInformations.StatusCode === 6000) {
        let file = [];
        if (userInformations.data.profile_pic) {
          file = await dataURLtoFile(userInformations.data.profile_pic);
          file = Array.isArray(file) && file.length > 0 ? file[0].originFileObj : null;
        }
        
        setFileList(file);
        setState1((prevState) => ({
          ...prevState,
          profilePic: userInformations.data.profile_pic,
        }));
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };
  
  useEffect(() => {
    FetchData();
  }, []);
  useEffect(() => {
    if (!access) {
      navigate("/signin");
    }
  }, [access]);
  const handleLogout = () => {
    let dic = {
      username: username,
      email: email,
      image_url:
        "https://www.api.viknbooks.com/media/profiles/Screenshot_from_2022-02-02_11-45-23.png",
    };
    set_handleLogout(dic);
    setAccess("");
    setcallRefresh(true);
    dispatch(logoutSuccess());
    document.cookie = `VBID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain}`;
    document.cookie = `usrtp=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;domain=${domain}`;
  };
  console.log(state1, "state1");
  return (
    <Container>
      <LeftContainer>
        <MenuIconContainer1
          hidemenu={hidemenu}
          onClick={() => setShowMenu(!showMenu)}
        >
          <MenuIcon1 />
        </MenuIconContainer1>

        <ImgContainer onClick={() => navigate("/")}>
          <ImgSvg src="../../images/1.svg" alt="" />
        </ImgContainer>
      </LeftContainer>
      <RightContainer>
        <AdminContainer>
          <AppsContainer onClick={() => setMenu1(true)}>
            <IconButton>
              <AppsIcon style={{ color: "#003D65", padding: "0px" }} />
            </IconButton>

            <AllAppsTxt>{t("All Apps")}</AllAppsTxt>
          </AppsContainer>
          <Avatar
            style={{ width: "36px", height: "36px" }}
            alt="Remy Sharp"
            src={state1.profilePic}
          />
        </AdminContainer>

        <MenuIconContainer>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={open ? "long-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <MoreVertIcon />
          </IconButton>

          <MenuContainer
            disableScrollLock={true}
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button",
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 5.5,
                width: "20ch",
              },
            }}
          >
            {/* <DropMenuSelectList
              onClick={() => history.push("/dashboard/general-settings")}
            >
              {"General Settings"}
            </DropMenuSelectList> */}
            {options.map((option) => (
              <MenuItems
                key={option}
                selected={option === "Logout"}
                onClick={(e) => {
                  handleLogout(e);
                }}
              >
                {option.title}
              </MenuItems>
            ))}
          </MenuContainer>
        </MenuIconContainer>
      </RightContainer>

      <StyledMenuContainer menu1={menu1}>
        <ListIem>
          <StyledMenu>
            <AllApps>{t("All Apps")}</AllApps>
            <CloseMenu onClick={() => setMenu1(false)} />
          </StyledMenu>
        </ListIem>
        <List>
          <ListIem1 menu1={menu1}>
            <StyledMenu1>
              <ViknContainer>
                <RassasyContainer>
                  <ImgLogo src="../../images/icons/vikinBooks.svg" />
                </RassasyContainer>
                <TextVikn
                  onClick={() =>
                    (window.location.href = VIKNBOOKS_FRONT_URL)
                  }
                >
                  Viknbooks
                </TextVikn>
              </ViknContainer>

              <FileIcon />
            </StyledMenu1>
          </ListIem1>
          <ListIem2 menu1={menu1}>
            <StyledMenu1>
              <ViknContainer>
                <RassasyContainer>
                  <ImgLogo src="../../images/icons/payroll.svg" />
                </RassasyContainer>
                <TextVikn>Payroll</TextVikn>
              </ViknContainer>

              <FileIcon />
            </StyledMenu1>
          </ListIem2>
          {/* <ListIem3 menu1={menu1}>
            <StyledMenu1>
              <ViknContainer>
                <RassasyContainer>
                  <ImgLogo src="../../images/icons/vikinBooks.svg" />
                </RassasyContainer>
                <TextVikn>Viknbooks</TextVikn>
              </ViknContainer>

              <FileIcon />
            </StyledMenu1>
          </ListIem3> */}
          <ListIem3 menu1={menu1}>
            <StyledMenu1>
              <ViknContainer>
                <RassasyContainer>
                  <ImgLogo src="../../images/icons/tokenPlus.svg" />
                </RassasyContainer>
                <TextVikn
                onClick={() =>
                    (window.location.href = TOKEN_FRONT_URL)
                  }
                >Token Plus</TextVikn>
              </ViknContainer>

              <FileIcon />
            </StyledMenu1>
          </ListIem3>
        </List>
      </StyledMenuContainer>
    </Container>
  );
}

export default Header;

const FileIcon = styled(FolderOpenOutlinedIcon)`
  && {
    color: #b7b7b7;
  }
`;
const ViknContainer = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
`;

const TextVikn = styled.span`
  font-size: 13px;
  font-weight: 400;
`;
const RassasyContainer = styled.div`
  width: 22px;
  height: 22px;
`;
const ImgLogo = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
const ListIem = styled.div`
  margin-top: 10px;
`;
const ListIem1 = styled.li`
  margin-top: 10px;
  right: ${({ menu1 }) => (menu1 ? "40px" : "-400px")};
  transition: all 0.4s ease-in-out;
  position: absolute;
  width: 210px;
  top: 0px;
`;
const ListIem2 = styled.li`
  margin-top: 10px;
  right: ${({ menu1 }) => (menu1 ? "40px" : "-400px")};
  transition: all 0.5s ease-in-out;
  position: absolute;
  top: 58px;
  width: 210px;
`;
const ListIem3 = styled.li`
  transition: all 0.6s ease-in-out;
  margin-top: 10px;
  right: ${({ menu1 }) => (menu1 ? "40px" : "-400px")};
  position: absolute;
  top: 114px;
  width: 210px;
`;
const CloseMenu = styled(CloseIcon)`
  && {
    cursor: pointer;
    color: white;
  }
`;

const AllApps = styled.span`
  font-size: 21px;
  color: white;
`;

const List = styled.ul`
  list-style: none;
  position: relative;
  height: 200px;
  width: 251px;
  padding: unset;
`;
const StyledMenu = styled.div`
  display: flex;

  padding: 10px;
  align-items: center;
  justify-content: space-between;
`;

const StyledMenu1 = styled(StyledMenu)`
  padding: 10px;
  border-radius: 22px;
  cursor: pointer;

  background-color: white;
  color: black;
`;
const StyledMenuContainer = styled.div`
  width: 210px;
  padding-left: 57px;
  height: 199vh;
  background-image: linear-gradient(270deg, #398c89, transparent);
  width: 210px;
  top: 54px;
  position: absolute;
  right: ${({ menu1 }) => (menu1 ? "0px" : "-400px")};
  transition: all 0.3s;
  /* top: 80px; */
  padding-right: 20px;
`;
const MenuIconContainer1 = styled(IconButton)`
  && {
    display: none;
    @media (max-width: 1008px) {
      display: ${({ hidemenu }) => (hidemenu ? "none" : "block")};
    }
  }
`;
const MenuIcon1 = styled(MenuIcon)`
  color: black;
`;

const LeftContainer = styled.div`
  display: flex;
  gap: 3px;
  align-items: center;

  margin-left: 10px;
`;

const ImgSvg = styled.img`
  width: 100%;
  object-fit: contain;
  height: 100%;
`;
const ImgContainer = styled.div`
  width: 114.93px;
  height: 27.74px;
  cursor: pointer;

  @media (max-width: 731px) {
    width: 92.93px;
  }
`;

const AppICon = styled(AppsIcon)`
  && {
    font-size: 1 0.5rem;
    padding: unset;
  }
`;
const AllAppsTxt = styled.span`
  font-size: 11px;
  user-select: none;
`;
const AppsContainer = styled.span`
  /* padding: 5px; */
  background-color: #f6f6f6;
  border-radius: 19px;
  display: flex;
  cursor: pointer;
  padding-right: 15px;

  align-items: center;
  /* gap: 10px; */
`;
const MenuItems = styled(MenuItem)`
  font-size: 13px !important;
`;
const MenuContainer = styled(Menu)`
  .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    font-family: "Poppins", sans-serif;
  }
  .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
    max-height: 194px !important;
    ::-webkit-scrollbar {
      display: none;
    }
  }
`;
const DropMenuSelectList = styled.div`
  margin: 5px;
  padding: 8px 35px 8px 8px;
  border-radius: 5px;
  &:hover {
    cursor: pointer;
    background: #e5e5e5;
  }
`;

const Notification = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .css-jcn4dz-MuiBadge-badge {
    background-color: #e20000 !important ;
  }
  svg {
    color: black;
  }
`;
const MenuIconContainer = styled.div`
  svg {
    font-size: 1.5rem !important ;
    color: black !important;
  }
`;
const AdminTxt = styled.span`
  font-size: 15px;
  font-weight: 600;
`;
const RightContainer = styled.div`
  display: flex;
  justify-content: space-around;
  min-width: 163px;

  margin-right: 10px;
`;
const Container = styled.div`
  height: 56px;
  position: fixed;
  top: 0;
  background-color: white;

  display: flex;
  align-items: center;
  border-radius: 0px 0px 28px 28px;

  z-index: 9;

  width: 100%;

  justify-content: space-between;
`;

const AdminContainer = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
